import { Button, Flex, Input } from "antd";
import { DeleteFilled, DeleteOutlined, FilterOutlined, PlusOutlined, RedoOutlined, RollbackOutlined } from '@ant-design/icons';
import { useCallback, useRef, useState } from "react";
import { debounce } from "@mui/material";

import ActionDropdown from "./ActionDropdown";
import UploadMenu, { UploadMenuRef } from "components/UploadMenu";
import UserAllowed from "components/UserAllowed";
import { BaseModalRef } from "modals/BaseModal";
import CategoryCreateModal from "modals/CategoryCreateModal";
import FilterModal from "modals/FilterModal";
import { setParams } from "slices/documentsSlice";
import { useAllowed, useAppDispatch } from "utilities/hooks";
import FilterChips from "./FilterChips";
import ConfirmationModal from "modals/ConfirmationModal";
import { fetchDeletedDocuments, foreverDeleteDocument, foreverDeleteTrashed, restoreDeletedDocument } from "slices/documentsActions";
import { Category, Document } from "interfaces/documents";
import { foreverDeleteCategory, restoreDeletedCategory } from "slices/categoriesActions";

const DocumentsToolbar = ({
    categoryId = null,
    fetchData,
    selected,
    shared = false,
    deleted = false,
}: {
    categoryId?: number | null,
    fetchData: () => void,
    selected: any[],
    shared?: boolean,
    deleted?: boolean,
}) => {
    const dispatch = useAppDispatch();

    const categoryCreateModal = useRef<BaseModalRef>(null);
    const confirmationDeleteForeverAllModal = useRef<BaseModalRef>(null);
    const confirmationDeleteForeverModal = useRef<BaseModalRef>(null);
    const confirmationRestoreModal = useRef<BaseModalRef>(null);
    const filterModal = useRef<BaseModalRef>(null);
    const uploadMenuRef = useRef<UploadMenuRef>(null);
    const [uploadMenuAnchor, setUploadMenuAnchor] = useState<null | HTMLElement>(null);

    const handleUploadMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setUploadMenuAnchor(event.currentTarget);
    };

    const handleUploadMenuClose = () => {
        setUploadMenuAnchor(null);
    };

    const handleUploadMenuComplete = (values: any[]) => {
        fetchData();
    };

    const handleConfirmDeleteForeverAll = () => {
        dispatch(foreverDeleteTrashed())
            .unwrap()
            .then(() => {
                dispatch(fetchDeletedDocuments());
            }).catch(() => { });
    };

    const handleConfirmDeleteForever = () => {
        handleDeleteForever();
    };

    const handleDeleteForever = () => {
        let promises: any[] = [];
        selected.map((item: Category | Document) => {
            if (item.type === 'category') promises.push(dispatch(foreverDeleteCategory(item.id)));
            else if (item.type === 'document') promises.push(dispatch(foreverDeleteDocument(item.id)));
        });

        Promise.all(promises).then((responses) => {
            dispatch(fetchDeletedDocuments());
        }).catch(() => { });
    };

    const handleConfirmRestore = () => {
        handleRestore();
    };

    const handleRestore = () => {
        let promises: any[] = [];
        selected.map((item: Category | Document) => {
            if (item.type === 'category') promises.push(dispatch(restoreDeletedCategory(item.id)));
            else if (item.type === 'document') promises.push(dispatch(restoreDeletedDocument(item.id)));
        });

        Promise.all(promises).then((responses) => {
            dispatch(fetchDeletedDocuments());
        }).catch(() => { });
    };


    const debouncedSearch = useCallback(
        debounce((value) => {
            dispatch(setParams({ query: value || '' }));
            fetchData();
        }, 500),
        []
    );

    return (
        <>
            {deleted && selected.length > 0 ?
                <Flex style={{ marginBottom: '20px' }}>
                    <UserAllowed permissions={['files.manage', 'files.project.manage']}>
                        <Button
                            type="primary"
                            onClick={() => confirmationDeleteForeverModal.current?.open()}
                            icon={<DeleteOutlined />}
                        >
                            Delete Forever
                        </Button>
                    </UserAllowed>
                    <UserAllowed permissions={['files.manage', 'files.project.manage']}>
                        <Button
                            type="text"
                            onClick={() => confirmationRestoreModal.current?.open()}
                            icon={<RollbackOutlined />}
                        >
                            Restore
                        </Button>
                    </UserAllowed>
                </Flex>
                :
                <Flex style={{ marginBottom: '20px' }}>
                    {!shared && !deleted ?
                        <>
                            <UserAllowed permissions={['files.create', 'files.manage', 'files.project.create', 'files.project.manage', 'files.test']}>
                                <Button
                                    type="primary"
                                    onClick={handleUploadMenuClick}
                                    icon={<PlusOutlined />}
                                >Upload</Button>
                            </UserAllowed>
                            <UserAllowed permissions={['files.create', 'files.manage', 'files.project.create', 'files.project.manage']}>
                                <Button
                                    type="text"
                                    onClick={() => categoryCreateModal?.current?.open()}
                                    icon={<PlusOutlined />}
                                >
                                    Create Folder
                                </Button>
                            </UserAllowed>
                        </>
                        :
                        <></>
                    }
                    {deleted ?
                        <UserAllowed permissions={['files.manage', 'files.project.manage']}>
                            <Button
                                type="primary"
                                onClick={() => confirmationDeleteForeverAllModal.current?.open()}
                                icon={<DeleteFilled />}
                            >
                                Empty Bin
                            </Button>
                        </UserAllowed>
                        : <></>
                    }
                    <Button
                        type="text"
                        onClick={fetchData}
                        icon={<RedoOutlined />}
                    >
                        Refresh
                    </Button>
                </Flex>
            }
            {!shared && !deleted ?
                <FilterChips
                    fetchData={fetchData}
                ></FilterChips>
                : <></>
            }
            {!shared && !deleted ?
                <Flex justify="space-between" style={{ marginBottom: '20px' }}>
                    <Input
                        suffix={<FilterOutlined onClick={() => filterModal?.current?.open()} />}
                        placeholder="Search"
                        style={{ width: '20%' }}
                        onChange={(e) => debouncedSearch(e.target.value)}
                    />
                    <UserAllowed permissions={['files.manage', 'files.project.manage']}>
                        <ActionDropdown
                            fetchData={fetchData}
                            selected={selected}
                        />
                    </UserAllowed>
                </Flex>
                : <></>
            }

            <CategoryCreateModal
                ref={categoryCreateModal}
                parent={categoryId!}
                onSubmit={fetchData}
            ></CategoryCreateModal>
            <ConfirmationModal
                ref={confirmationDeleteForeverAllModal}
                text='Are you sure want to delete forever all items?'
                confirmLabel='Delete Forever'
                onConfirm={handleConfirmDeleteForeverAll}
            ></ConfirmationModal>
            <ConfirmationModal
                ref={confirmationDeleteForeverModal}
                text='Are you sure want to delete forever selected items?'
                confirmLabel='Delete Forever'
                onConfirm={handleConfirmDeleteForever}
            ></ConfirmationModal>
            <ConfirmationModal
                ref={confirmationRestoreModal}
                text='Are you sure want to restore selected items?'
                confirmLabel='Restore'
                onConfirm={handleConfirmRestore}
            ></ConfirmationModal>
            <FilterModal
                ref={filterModal}
                onSubmit={fetchData}
            ></FilterModal>
            <UploadMenu
                uploadref={uploadMenuRef}
                anchorEl={uploadMenuAnchor}
                open={Boolean(uploadMenuAnchor)}
                onClose={handleUploadMenuClose}
                onComplete={handleUploadMenuComplete}
            ></UploadMenu>
        </>
    );
};

export default DocumentsToolbar;
