import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { Empty, Spin } from 'antd';

interface ILoadingSpin {
    children: React.ReactNode;
}

const WaitingSpinForLoadItem: React.FC<ILoadingSpin> = ({ children }) => {
    const isGantLoaded = useSelector<RootState, boolean>((state) => state.schedule.isGantLoaded);

    return isGantLoaded ? (
        <>{children}</>
    ) : (
        <Spin tip={'Loading...'} spinning>
            <Empty style={{ marginTop: 135 }} description={'No data to display'} />
        </Spin>
    );
};

export default WaitingSpinForLoadItem;
