import { SmxDrawerPanel } from 'smx-components';
import { Button, Carousel, Flex } from 'antd';
import ButtonScale from './buttonScale';
import { CSSProperties, useRef } from 'react';
import ControlEditMode from './controlEditMode';
import WrapperGant from './WrapperGant';
import WrapperCalendar from './WrapperCalendar';
import useSwitchScheduleTabs from '../../../../hooks/useSwitchScheduleTabs';
import WrapperCalendarTable from './WrapperCalendarTable';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { setterSchedule } from '../../../../slices/scheduleSlice';
import { useAppDispatch } from '../../../../utilities/hooks';
import WrapperGantTable from './WrapperGantTable';
import WaitingSpinForLoadItem from './WaitingSpinForLoadItem';

const SchedulePage = () => {
    const dispatch = useAppDispatch();
    const carouselRef = useRef<any>();

    const isFullScreen = useSelector<RootState, boolean>((state) => state.schedule.isFullScreen);
    useSwitchScheduleTabs(carouselRef);

    const stylePopup: CSSProperties = {
        backgroundColor: 'white',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1200,
        padding: 20,
    };

    const style: CSSProperties = {};

    return (
        <div style={isFullScreen ? stylePopup : style}>
            <Flex vertical>
                <Flex justify={'space-between'}>
                    <ControlEditMode />
                    <Button
                        icon={isFullScreen ? <FullscreenOutlined /> : <FullscreenExitOutlined />}
                        onClick={() => dispatch(setterSchedule({ isFullScreen: !isFullScreen }))}
                    >
                        {isFullScreen ? 'Exit' : 'FullScreen'}
                    </Button>
                    <ButtonScale />
                </Flex>
                <Carousel ref={carouselRef} dots={false} lazyLoad={'progressive'} effect="fade">
                    <div>
                        <WaitingSpinForLoadItem>
                            <WrapperGantTable />
                        </WaitingSpinForLoadItem>
                    </div>
                    <div>
                        <WaitingSpinForLoadItem>
                            <WrapperCalendarTable />
                        </WaitingSpinForLoadItem>
                    </div>
                    <div>
                        <WrapperGant />
                    </div>
                    <div>
                        <WaitingSpinForLoadItem>
                            <WrapperCalendar />
                        </WaitingSpinForLoadItem>
                    </div>
                    <div></div>
                </Carousel>
                <SmxDrawerPanel />
            </Flex>
        </div>
    );
};

export default SchedulePage;
