import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IAppState {
    globalFilterProject: string[];
}

const initialState: IAppState = {
    globalFilterProject: [],
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setterApp: (state, action: PayloadAction<Partial<IAppState>>) => ({ ...state, ...action.payload }),
    },
});

export const { setterApp } = appSlice.actions;

export default appSlice.reducer;
