import { Dropdown } from "antd";
import { ArrowRightOutlined, CopyOutlined, DeleteOutlined, EditOutlined, MoreOutlined, RollbackOutlined, ShareAltOutlined } from '@ant-design/icons';
import { useRef, useState } from "react";

import { Category, Document } from "interfaces/documents";
import { BaseModalRef } from "modals/BaseModal";
import CategoryRenameModal from "modals/CategoryRenameModal";
import CategoriesTreeModal from "modals/CategoriesTreeModal";
import ConfirmationModal from "modals/ConfirmationModal";
import DocumentRenameModal from "modals/DocumentRenameModal";
import DocumentVersionsModal from "modals/DocumentVersionsModal";
import GuestTeamModal from "modals/GuestTeamModal";
import { moveCategory, softDeleteCategory } from "slices/categoriesActions";
import { setExcluded } from "slices/categoriesSlice";
import { setCategory } from "slices/categorySlice";
import { setDocument } from "slices/documentSlice";
import { copyDocument, moveDocument, softDeleteDocument } from "slices/documentsActions";
import { useAllowed, useAppDispatch, useAppSelector } from "utilities/hooks";

const ItemActionList = ({
    fetchData,
    row,
    shared,
}: {
    fetchData: () => void,
    row: Category | Document,
    shared?: boolean,
}) => {
    const dispatch = useAppDispatch();
    const {
        teams,
    } = useAppSelector((state) => state.documents);
    const [currentItem, setCurrentItem] = useState<null | any>(null);
    const [currentTeam, setCurrentTeam] = useState<null | any>(null);

    const isAllowedManage = useAllowed(['files.manage']);
    const isAllowedEdit = useAllowed(['files.edit', 'files.manage']);

    const categoryRenameModal = useRef<BaseModalRef>(null);
    const confirmRowDeleteModal = useRef<BaseModalRef>(null);
    const copyRowModal = useRef<BaseModalRef>(null);
    const documentRenameModal = useRef<BaseModalRef>(null);
    const documentVersionsModal = useRef<BaseModalRef>(null);
    const guestTeamModal = useRef<BaseModalRef>(null);
    const moveRowModal = useRef<BaseModalRef>(null);

    const openGuestTeamModal = (item: any) => {
        let team: any = null;
        if (item.type === 'category') {
            team = teams[item?.uuid];
        } else {
            team = teams[item?.id];
        }

        setCurrentTeam(team);

        guestTeamModal?.current?.open();
    };

    const openVersions = (item: any) => {
        dispatch(setDocument(item));
        documentVersionsModal.current?.open();
    };

    const openCategoryRenameModal = (item: Category) => {
        dispatch(setCategory(item));
        categoryRenameModal.current?.open();
    };

    const openDocumentRenameModal = (item: Document) => {
        dispatch(setDocument(item));
        documentRenameModal.current?.open();
    };

    const rename = (item: any | Array<any>) => {
        if (!item) return;
        if (Array.isArray(item)) {
            if (item.length === 0) return;
            item = item[0];
        }
        setCurrentItem(item);
        if ((item.type === 'category')) {
            openCategoryRenameModal(item);
        } else {
            openDocumentRenameModal(item);
        }
    };

    const openCopyRowModal = (item: any) => {
        if (!item) return;
        setCurrentItem(item);

        copyRowModal.current?.open();
    };

    const copyItem = (destination: Category | null) => {
        let promises: any[] = [];

        promises.push(dispatch(copyDocument({
            id: currentItem.id,
            category_id: destination?.id || null,
        })));

        setCurrentItem(null);
        fetchData();
    };

    const openMoveModal = (item: any) => {
        if (!item) return;
        setCurrentItem(item);
        if (item.type === 'category') {
            dispatch(setExcluded([item]));
        }

        moveRowModal.current?.open();
    };

    const moveItem = (destination: Category | null) => {
        let promises: any[] = [];

        if (currentItem.type === 'category') {
            promises.push(dispatch(moveCategory({
                id: currentItem.id,
                parent_id: destination?.id || null,
            })));
        } else {
            promises.push(dispatch(moveDocument({
                id: currentItem.id,
                category_id: destination?.id || null,
            })));
        }

        setCurrentItem(null);
        fetchData();
    };

    const openDeleteItemModal = (item: any) => {
        if (!item) return;
        setCurrentItem(item);
        confirmRowDeleteModal.current?.open();
    };

    const deleteItem = (hasConfirmed: Boolean) => {
        if (!hasConfirmed) return;

        let promises: any[] = [];
        if (currentItem.type === 'category') {
            promises.push(dispatch(softDeleteCategory(currentItem.id)));
        } else {
            promises.push(dispatch(softDeleteDocument(currentItem.id)));
        }

        setCurrentItem(null);
        fetchData();
    };

    const checkPermission = (permissions: string[]) => {
        if (!row.permissions) return true;
        return row.permissions?.some((permission) => permissions.includes(permission));
    };

    let sharingBtn = {
        key: 'ManageSharing',
        label: 'Manage Sharing',
        onClick: () => {
            openGuestTeamModal(row);
        },
        icon: <ShareAltOutlined />
    }
    let versionBtn = {
        key: 'Versions',
        label: 'Versions',
        onClick: () => {
            openVersions(row);
        },
        icon: <RollbackOutlined />
    }
    let copyBtn = {
        key: 'Copy',
        label: 'Copy',
        onClick: () => {
            openCopyRowModal(row);
        },
        icon: <CopyOutlined />,
    }
    let renameBtn = {
        key: 'Rename',
        label: 'Rename',
        onClick: () => {
            rename(row);
        },
        icon: <EditOutlined />,
    }
    let moveBtn = {
        key: 'Move',
        label: 'Move',
        onClick: () => {
            openMoveModal(row);
        },
        icon: <ArrowRightOutlined />,
    }
    let deleteBtn = {
        key: 'Delete',
        label: 'Delete',
        onClick: () => {
            openDeleteItemModal(row);
        },
        icon: <DeleteOutlined />,
    }

    let items = [];

    if (shared) {
        if (!(row as Category).is_locked && checkPermission(['files.manage'])) {
            items.push(sharingBtn, versionBtn, copyBtn);
        }
        if (!(row as Category).is_locked && checkPermission(['files.edit', 'files.manage'])) {
            items.push(renameBtn, moveBtn, deleteBtn);
        }
    } else {
        if (isAllowedManage) {
            items.push(sharingBtn, versionBtn, copyBtn);
        }
        if (isAllowedEdit) {
            items.push(renameBtn, moveBtn, deleteBtn);
        }
    }

    return (
        <>
            <Dropdown menu={{ items }}>
                <a
                    onClick={(e) => {
                        e.preventDefault();
                    }}
                >
                    <MoreOutlined />
                </a>
            </Dropdown>

            <CategoryRenameModal
                ref={categoryRenameModal}
                onSubmit={fetchData}
            ></CategoryRenameModal>
            <CategoriesTreeModal
                ref={copyRowModal}
                title={`Copy ${currentItem?.type == 'category' ? 'folder' : 'document'} to...`}
                submitLabel='Copy'
                onSubmit={copyItem}
            ></CategoriesTreeModal>
            <CategoriesTreeModal
                ref={moveRowModal}
                title={`Move ${currentItem?.type == 'category' ? 'folder' : 'document'} to...`}
                submitLabel='Move'
                onSubmit={moveItem}
            ></CategoriesTreeModal>
            <ConfirmationModal
                ref={confirmRowDeleteModal}
                text='Are you sure want to delete item?'
                onConfirm={deleteItem}
            ></ConfirmationModal>
            <DocumentRenameModal
                ref={documentRenameModal}
                onSubmit={fetchData}
            ></DocumentRenameModal>
            <DocumentVersionsModal
                ref={documentVersionsModal}
                onClose={fetchData}
            ></DocumentVersionsModal>
            <GuestTeamModal
                ref={guestTeamModal}
                onClose={fetchData}
                entity={row}
            ></GuestTeamModal>
        </>
    );
}

export default ItemActionList;
