import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { CalendarViewType } from 'smx-components';
import React, { useEffect } from 'react';
import { SchedulePageViewType, ScheduleViewType } from '../types';

const useSwitchScheduleTabs = (carouselRef: React.MutableRefObject<any>) => {
    const activeCalendarPage = useSelector<RootState, CalendarViewType>((state) => state.schedule.activeCalendarPage);
    const schedulePageView = useSelector<RootState, SchedulePageViewType | undefined>(
        (state) => state.schedule.schedulePageView,
    );
    const view = useSelector<RootState, ScheduleViewType>((state) => state.schedule.view);

    const getNumberSlide = () => {
        if (view === 'calendar' && activeCalendarPage === 'table' && schedulePageView === 'listItem') return 0;
        if (view === 'calendar' && activeCalendarPage === 'table' && schedulePageView === 'listTask') return 1;
        if (view === 'gant') return 2;
        if (view === 'calendar' && activeCalendarPage === 'day') return 3;
        if (view === 'calendar' && activeCalendarPage === 'agenda') return 4;
        return 0;
    };

    useEffect(() => {
        const newCurrentSlide = getNumberSlide();
        carouselRef.current.goTo(newCurrentSlide);
    }, [activeCalendarPage, view, schedulePageView]);
};

export default useSwitchScheduleTabs;
