import { Gant } from 'smx-components';
import { useAppSelector } from '../../../../utilities/hooks';

const WrapperGantTable = () => {
    const user = useAppSelector((state) => state.account.user);
    const isFullScreen = useAppSelector((state) => state.schedule.isFullScreen);
    const globalFilterProject = useAppSelector((state) => state.app.globalFilterProject);

    return user ? (
        <Gant
            paddingBottom={!isFullScreen ? 20 : 0}
            mode={'table'}
            authorId={user?.id || ''}
            filterProject={globalFilterProject}
        />
    ) : null;
};

export default WrapperGantTable;
