import {
    Stack,
} from '@mui/material';

import InvitationsList from 'components/InvitationsList';
import MembersList from 'components/MembersList';
import UsersList from 'components/UsersList';

import { TeamMemberRole } from 'interfaces/teams';
import { User } from 'interfaces/users';

import BaseModal, { BaseModalRef } from 'modals/BaseModal';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';

import { fetchUsers as fetchCategoryUsers } from 'slices/categoriesActions';
import { fetchUsers as fetchDocumentUsers } from 'slices/documentsActions';
import { fetchInvitations, syncInvitations } from 'slices/invitationsActions';
import { fetchGuestTeamMembers, updateGuestTeamMembers } from 'slices/teamsActions';
import { reset as resetTeam } from 'slices/teamSlice';

import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import Actions from './Actions';
import { Category, Document } from 'interfaces/documents';
import { Invitation } from 'interfaces/invitations';
import SelectTabs from './SelectTabs';

export interface GuestTeamModalProps {
    entity?: Category | Document;
    onSubmit?: () => void;
    onClose?: () => void;
};

const GuestTeamModal = (
    props: GuestTeamModalProps,
    ref: React.Ref<unknown>
) => {
    const { entity, onSubmit, onClose } = props;
    const entityId = entity?.type === 'category' ? (entity as Category)?.uuid : (entity as Document)?.id;

    const modal = useRef<BaseModalRef>(null);

    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.account);

    const [membersSelected, setMembersSelected] = useState<User[]>([]);
    const [invitationsSelected, setInvitationsSelected] = useState<Invitation[]>([]);
    const [role, setRole] = useState(TeamMemberRole.VIEWER);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState<string>('members');
    const [entityUsersLoading, setEntityUsersLoading] = useState<boolean>(false);
    const [entityUsers, setEntityUsers] = useState<User[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [excludedMembers, setExcludedMembers] = useState<string[]>([]);

    const {
        loading: teamLoading,
        members: teamMembers,
        membersMap: teamMembersMap
    } = useAppSelector((state) => state.team);
    const {
        invitations
    } = useAppSelector((state) => state.invitations);

    const teamMembersRef = useRef<User[]>(teamMembers || []);
    const invitationsRef = useRef<Invitation[]>(invitations || []);

    useEffect(() => {
        setIsLoaded(!(teamLoading || entityUsersLoading));
        if (!(teamLoading || entityUsersLoading)) {
            const existingMembers = (teamMembers || []).concat((entityUsers || [])).map((member: User) => member.id);
            setExcludedMembers(Array.from(new Set(existingMembers)));
        }
    }, [teamLoading, entityUsersLoading]);

    useEffect(() => {
        if (!isOpen) return;
        resetDefaults();

        if (entity) {
            const fetchEntityUsersAction = entity?.type === 'category' ? fetchCategoryUsers : fetchDocumentUsers;
            setEntityUsersLoading(true);
            dispatch(fetchEntityUsersAction(entityId)).then(response => {
                setEntityUsers(response.payload.data);
            }).finally(() => {
                setEntityUsersLoading(false);
            });

            dispatch(fetchGuestTeamMembers(entityId));
            dispatch(fetchInvitations(entityId));
        }
    }, [isOpen]);

    useEffect(() => {
        if (!isOpen) return;
        if (teamMembersRef.current.length > (teamMembers || []).length) {
            let data: any = {
                entityId: entityId,
                members: (teamMembers || []).map((member: User) => {
                    return {
                        id: member.id,
                        role: teamMembersMap[member.id]?.role,
                    };
                }),
            };
            dispatch(updateGuestTeamMembers(data));
        }
        teamMembersRef.current = teamMembers || [];
    }, [teamMembers]);

    useEffect(() => {
        if (!isOpen) return;
        if (invitationsRef.current.length > (invitations || []).length) {
            let data: any = {
                teamId: entityId,
                invitations: (invitations || []).map((invitation: Invitation) => {
                    return {
                        id: invitation.id,
                        email: invitation.email,
                        phone: invitation.phone,
                        team_role: role,
                    };
                }),
            };
            dispatch(syncInvitations(data));
        }
        invitationsRef.current = invitations || [];
    }, [invitations]);

    const resetDefaults = () => {
        setRole(TeamMemberRole.VIEWER);
        setMembersSelected([]);
        setInvitationsSelected([]);
    };

    /**
     * Modal
     */
    const open = () => {
        modal?.current?.open();
    };

    const close = () => {
        modal?.current?.close();
    };

    useImperativeHandle(ref, () => ({
        open: () => open(),
        close: () => close(),
    }));

    const handleOnClose = () => {
        dispatch(resetTeam());
        onClose && onClose();
    };

    return <>
        <BaseModal
            ref={modal}
            title={'Manage sharing'}
            maxWidth={'md'}
            open={isOpen}
            setOpen={setIsOpen}
            onClose={handleOnClose}
            actions={
                <Actions
                    modal={modal}
                    onSubmit={onSubmit || (() => {})}
                    role={role}
                    membersSelected={membersSelected}
                    invitationsSelected={invitationsSelected}
                    entity={entity}
                    currentTab={currentTab}
                />
            }
        >
            { isOpen ?
                <Stack spacing={2}>
                    <SelectTabs
                        isLoaded={isLoaded}
                        setMembersSelected={setMembersSelected}
                        setInvitationsSelected={setInvitationsSelected}
                        setCurrentTab={setCurrentTab}
                        excludedMembers={excludedMembers}
                        role={role}
                        setRole={setRole}
                    />

                    {
                        teamMembers ?
                            <MembersList
                                members={teamMembers}
                                membersMap={teamMembersMap}
                                membersConstrained={user && [user.id]}
                            ></MembersList>
                            : <></>
                    }
                    {
                        invitations ?
                            <InvitationsList
                                invitations={invitations}
                                onResend={() => {}}
                            ></InvitationsList>
                            : <></>
                    }
                    {
                        entityUsers ?
                            <UsersList
                                users={entityUsers}
                            ></UsersList>
                            : <></>
                    }
                </Stack>
                : <></>
        }
        </BaseModal>
    </>
};

export default forwardRef(GuestTeamModal);
