import { Grid } from '@mui/material';
import CustomBreadCrumbs from 'components/CustomBreadCrumbs';
import { useDocumentsPage } from 'hooks/useDocumentsPage';
import NewDocumentsTable from '../components/NewDocumentsTable';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import DocumentMarkupModal from 'modals/DocumentMarkupModal';
import { BaseModalRef } from 'modals/BaseModal';
import { setDocument } from 'slices/documentSlice';
import { useAppDispatch } from 'utilities/hooks';

const SharedDocumentsPage = () => {
    const dispatch = useAppDispatch();
    const documentMarkupModal = useRef<BaseModalRef>(null);
    const [searchParams] = useSearchParams();
    const documentId = searchParams.get('document');
    const {
        breadcrumbs,
        loading,
        navigateTo,
        list,
    } = useDocumentsPage({ isShared: true }); // Pass true to indicate shared documents { isShared: true }

    useEffect(() => {
        if (documentId && list.length > 0) {
            const document = list.find((item: any) => item.id === documentId);
            if (document) {
                dispatch(setDocument(document));
                documentMarkupModal.current?.open();
            }
        }
    }, [documentId, list]);

    return (
        <>
            <Grid item xs={8} sx={{ pt: 2, pb: 3 }}>
                <CustomBreadCrumbs
                    title='Shared Documents'
                    loading={loading}
                    breadcrumbs={breadcrumbs}
                    onRootClick={() => {
                        navigateTo();
                    }}
                    onClick={(breadcrumb) => {
                        navigateTo(breadcrumb.id);
                    }}
                />
            </Grid>
            <NewDocumentsTable shared />

            <DocumentMarkupModal
                ref={documentMarkupModal}
            ></DocumentMarkupModal>
        </>
    );
};

export default SharedDocumentsPage;
