import {
    Grid,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Menu,
    MenuItem,
    Typography,
    Divider,
} from "@mui/material";
import {
    DeleteSweep,
    DriveFileMove,
    People,
    Share,
    SyncAlt,
} from "@mui/icons-material";
import { bindMenu } from "material-ui-popup-state/hooks";
import { EntityContext, TeamsContext } from "interfaces/teams";
import CustomBreadCrumbs from "components/CustomBreadCrumbs";
import { useDocumentsPage } from "hooks/useDocumentsPage";
import TeamCreateModal from "modals/TeamCreateModal";
import TeamRenameModal from "modals/TeamRenameModal";
import TeamMembersUpdateModal from "modals/TeamMembersUpdateModal";
import ConfirmationModal from "modals/ConfirmationModal";
import GuestTeamCreateModal from "modals/GuestTeamCreateModal";
import GuestTeamMembersUpdateModal from "modals/GuestTeamMembersUpdateModal";
import CategoriesTreeModal from "modals/CategoriesTreeModal";
import NewDocumentsTable from "../components/NewDocumentsTable";
import { useAllowed } from "utilities/hooks";
import { useEffect } from "react";

const DefaultDocumentsPage = () => {
    const {
        currentTeam,
        integrations,
        breadcrumbs,
        loading,
        currentBreadcrumb,
        setCurrentBreadcrumb,
        currentEntity,
        teamCreateModal,
        teamMembersUpdateModal,
        teamRenameModal,
        confirmTeamDeleteModal,
        confirmDeleteBreadcrumbModal,
        guestTeamCreateModal,
        guestTeamMembersUpdateModal,
        moveRowModal,
        breadcrumbPopupState,
        rootBreadcrumbPopupState,
        fetchData,
        handleSyncIntegration,
        handleConfirmTeamDelete,
        openGuestTeamModal,
        openMoveModal,
        moveCurrentBreadcrumb,
        handleConfirmBreadcrumbDelete,
        navigateTo,
    } = useDocumentsPage();

    return (
        <>
            <Grid item xs={8} sx={{ pb: 2 }}>
                <CustomBreadCrumbs
                    title={currentTeam ? currentTeam.name : "Documents"}
                    loading={loading}
                    breadcrumbs={breadcrumbs}
                    onRootClick={() => {
                        navigateTo();
                    }}
                    onClick={(breadcrumb) => {
                        navigateTo(breadcrumb.id);
                    }}
                // onMenuClick={(event, breadcrumb) => {
                //     setCurrentBreadcrumb(breadcrumb);
                //     breadcrumbPopupState.open(event);
                // }}
                // onAccessClick={(event) => {
                //     rootBreadcrumbPopupState.open(event);
                // }}
                />
            </Grid>

            {/* <DocumentsTable */}

            <NewDocumentsTable hasFileTree={true} />

            <EntityContext.Provider value={currentEntity}>
                <GuestTeamCreateModal ref={guestTeamCreateModal} onClose={fetchData} />
                <GuestTeamMembersUpdateModal
                    ref={guestTeamMembersUpdateModal}
                    onClose={fetchData}
                />
            </EntityContext.Provider>

            <ConfirmationModal
                ref={confirmDeleteBreadcrumbModal}
                text="Are you sure you want to delete this item?"
                onConfirm={handleConfirmBreadcrumbDelete}
            />
            <ConfirmationModal
                ref={confirmTeamDeleteModal}
                text="Are you sure you want to delete this team?"
                onConfirm={handleConfirmTeamDelete}
            />

            <TeamsContext.Provider value={currentTeam}>
                <TeamCreateModal ref={teamCreateModal} onClose={fetchData} />
                <TeamRenameModal ref={teamRenameModal} onClose={fetchData} />
                <TeamMembersUpdateModal
                    ref={teamMembersUpdateModal}
                    onClose={fetchData}
                />
            </TeamsContext.Provider>

            <CategoriesTreeModal
                ref={moveRowModal}
                title={`Move category to...`}
                submitLabel="Move"
                onSubmit={moveCurrentBreadcrumb}
            />
        </>
    );
};

export default DefaultDocumentsPage;
