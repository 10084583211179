import '@fontsource/ibm-plex-sans/300.css';
import '@fontsource/ibm-plex-sans/400.css';
import '@fontsource/ibm-plex-sans/500.css';
import '@fontsource/ibm-plex-sans/700.css';
import 'gridstack/dist/gridstack.min.css';
import {
    COUNT_RECORD_QUERY,
    LIST_COST_CODE,
    LIST_DIVISION,
    LIST_PHASE,
    LIST_SCHEDULE_ITEM_STATUS,
    ListItemsQuery,
    ProjectQuery,
    ScheduleItemsQuery,
    SettingsQuery,
    TasksQuery,
    TasksShortQuery,
} from 'smx-components';
import { useLazyLoadQuery } from 'react-relay';
import { useAppSelector } from './utilities/hooks';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

function AppLoader() {
    const { projects } = useAppSelector((state) => state.projects);
    const [fetchKeyUpdateItemsForChangeProjectList, setFetchKeyUpdateItemsForChangeProjectList] = useState<string>('');

    const newOriginSetting = useLazyLoadQuery(SettingsQuery, {}, { fetchPolicy: 'network-only' });
    const newOriginScheduleItems = useLazyLoadQuery(
        ScheduleItemsQuery,
        { first: COUNT_RECORD_QUERY },
        { fetchPolicy: 'network-only', fetchKey: fetchKeyUpdateItemsForChangeProjectList },
    );
    const newOriginTasksShort = useLazyLoadQuery(TasksShortQuery, {}, { fetchPolicy: 'network-only' });
    // const newOriginTasks = useLazyLoadQuery(TasksQuery, {}, { fetchPolicy: 'network-only' });
    const newOriginPhase = useLazyLoadQuery(ListItemsQuery, { type: LIST_PHASE }, { fetchPolicy: 'network-only' });
    const newOriginProject = useLazyLoadQuery(ProjectQuery, {}, { fetchPolicy: 'network-only' });
    const newOriginDivision = useLazyLoadQuery(
        ListItemsQuery,
        { type: LIST_DIVISION },
        { fetchPolicy: 'network-only' },
    );
    const newOriginCostCode = useLazyLoadQuery(
        ListItemsQuery,
        { type: LIST_COST_CODE },
        { fetchPolicy: 'network-only' },
    );
    const newOriginScheduleItem = useLazyLoadQuery(
        ListItemsQuery,
        { type: LIST_SCHEDULE_ITEM_STATUS },
        { fetchPolicy: 'network-only' },
    );
    const newOriginTaskItem = useLazyLoadQuery(
        ListItemsQuery,
        { type: LIST_SCHEDULE_ITEM_STATUS },
        { fetchPolicy: 'network-only' },
    );
    // const newAttachments = useLazyLoadQuery(ListItemsQuery, { id: '00000000-0000-0000-0000-000000000000' }, { fetchPolicy: 'network-only' });

    useEffect(() => {
        setFetchKeyUpdateItemsForChangeProjectList(uuidv4());
    }, [projects]);

    return <></>;
}

export default AppLoader;
