import { Calendar } from 'smx-components';
import { useAppSelector } from '../../../../utilities/hooks';

const WrapperCalendarTable = () => {
    const isFullScreen = useAppSelector((state) => state.schedule.isFullScreen);
    const globalFilterProject = useAppSelector((state) => state.app.globalFilterProject);

    return <Calendar paddingBottom={!isFullScreen ? 40 : 0} activePage={'table'} filterProject={globalFilterProject} />;
};

export default WrapperCalendarTable;
