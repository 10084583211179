import { Calendar, ItemTaskType } from 'smx-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../utilities/hooks';
import { setterSchedule } from '../../../../slices/scheduleSlice';

const WrapperCalendar = () => {
    const dispatch = useAppDispatch();
    const triggerForNewTask = useSelector<RootState, ItemTaskType | undefined>(
        (state) => state.schedule.triggerForNewTask,
    );
    const globalFilterProject = useAppSelector((state) => state.app.globalFilterProject);

    useEffect(() => {
        if (triggerForNewTask) {
            dispatch(setterSchedule({ triggerForNewTask: undefined }));
        }
    }, [triggerForNewTask]);

    return (
        <Calendar
            paddingBottom={0}
            activePage={'day'}
            newTask={triggerForNewTask}
            filterProject={globalFilterProject}
        />
    );
};

export default WrapperCalendar;
